h1,
p {
  font-family: Arial;
}

#root {
  width: 100%;
}

.container {
  width: 90%;
  margin-left: auto;
  margin-right: auto;
}

table {
  border-collapse: collapse;
  width: 100%;
}

tr {
  border: solid thin;
}

footer, 
tbody.hover-red tr.error, 
tbody.hover-red tr:hover {
  background-color: red;
  color: white;
}

#rigaIntestazione {
  background-color: #808080;
  font-weight: 700;
}

.font-bold {
  font-size: 200%;
  font-weight: 700;
}

table th {
  color: #fff;
  padding: 4px;
  text-align: center;
}

table td {
  text-align: center;
  padding: 16px;
}

#rigaRicerca {
  margin-top: 32px;
  margin-bottom: 8px;
}

#rigaRicerca input,
#rigaRicerca select {
  margin-right: 16px;
  outline: 0;
  padding: 2px 4px;
  font-size: 110%;
}

.form-control {
  height: 38px !important;
  padding-left: 8px !important;
  padding-right: 8px !important;
}

tr.table-info td {
  color: darkgray !important;
}

tr.table-active td {
  font-weight: 700 !important;
}